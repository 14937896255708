@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  .placeholder-style::placeholder {
    color: var(--theme-blue);
  }
}
html {
  font-family: Montserrat, system-ui, sans-serif;
  font-size: 12px;
}
@screen md {
  html {
    font-size: 14px;
  }
}
@screen lg {
  html {
    font-size: 16px;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*input */
input:checked + div {
  @apply border-grey;
}
input:checked + div svg {
  @apply block;
}

.shadow-custom {
  box-shadow: 0px 4.2735px 4.2735px rgba(0, 0, 0, 0.25);
}

.shadow-filter-buttons {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.custom-clip {
  clip-path: polygon(35% 0, 100% 0, 100% 100%, 10% 100%);
}

.rec-carousel .rec-arrow-left {
  z-index: 100;
  color: var(--theme-blue);
  background-color: var(--theme-grey);
  opacity: 0.6;
}
.rec-carousel .rec-arrow-right {
  z-index: 100;
  color: var(--theme-blue);
  background-color: var(--theme-grey);
  opacity: 0.6;
}
.rec.rec-arrow:hover {
  color: var(--theme-blue);
  background-color: var(--theme-white);
  opacity: 0.6;
}

.rec-pagination {
  display: none !important;
}
.rec-carousel-wrapper {
  display: block !important;
}
.rec-slider-container {
  overflow: visible !important;
}
.carousel-wrapper > div {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.carousel-wrapper > div::-webkit-scrollbar {
  display: none;
}
.accounts-shadow {
  background: #fffef8;
  box-shadow: 10px -3px 13px -10px rgba(0, 0, 0, 0.25);
}

.custom-background-style {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
